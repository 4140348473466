import Contact from "../../components/Contact";
import About from "./about";
import Banner from "./banner";
import Choose from "./choose";
import Services from "./services";

export default function Home() {
  return (
    <div className="Home">
      <Banner />
      <About />
      <Services />
      <Choose />
      <Contact />
    </div>
  );
}
