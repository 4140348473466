import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Details from "./pages/details";
import Home from "./pages/home";
// import Vigilante from "vigilante-react";

export default function App() {
  return (
    <Router>
      <Header />
      {/* <Vigilante
        title="My Page Title"
        description="This is the description of my page"
        ogImage="https://example.com/og-image.jpg"
      /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<Details />} />
      </Routes>
      <Footer />
    </Router>
  );
}
