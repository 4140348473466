import "./style.scss";

export default function Choose() {
  return (
    <div className="P-home-choose G-mt-6" >
      <h2>ABOUT US</h2>
      <div className="G-mt-6">
        <h3>
          Our company is an experienced consulting company whose activities are
          related to regulatory compliance consulting for the US market. Our
          services cover all the necessary aspects to enter the US market for
          food, cosmetics and dietary supplements. <br />
          We are committed to providing the highest quality services for food,
          dietary supplements and cosmetics producers. <br />
          Our company  is headquartered in California, US.
        </h3>
      </div>
    </div>
  );
}
