import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Contact from "../../components/Contact";
import { Data } from "../../platform/data/data";

import "./style.scss";

export default function Details() {
  const { slug } = useParams();
  const details = Data.find((item) => item.name.toLowerCase().includes(slug));
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = details.name;
    document.querySelector('meta[name="description"]').content = details.description;
    document.querySelector('meta[property="og:title"]').content = details.name;
    document.querySelector('meta[property="og:image"]').content = details.images[0];
    document.querySelector('meta[property="og:description"]').content = details.description;

  }, [pathname]);

  return (
    <div className="P-details">
    
      <div className="P-details-content">
        <h1 className="G-mb-3">{details.name}</h1>
        <h2 style={{ whiteSpace: "pre-wrap" }}>
          {details.description.replace("<br/>", "\n")}
        </h2>
        <div className="P-details-images">
          {details.images.map((img, index) => (
            <img src={img} alt="" key={index} />
          ))}
        </div>
      </div>
      <Contact />
    </div>
  );
}
