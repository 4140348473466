import "./style.scss";

export default function About() {
  return (
    <div className="P-home-about G-mt-6" id="about">
      <h2>WHY CHOOSE US</h2>
      <div className="G-mt-6">
        <h3>&#x2022; Multilanguage support</h3>
        <h3>&#x2022; Fast Service</h3>
        <h3>&#x2022; Experienced</h3>
        <h3>&#x2022; Cost effective pricing and high quality services</h3>
      </div>
    </div>
  );
}
