import "./style.scss";

export default function Banner() {
  return (
    <div className="P-home-banner G-flex G-justify-center G-flex-column">
      <h1>MAKE FDA COMPLIANCE EASY</h1>
      <h2>
        Our company is an experienced consulting company whose activities are{" "}
        <br />
        related to regulatory compliance consulting for the US market. Our{" "}
        <br />
        services cover all the necessary aspects to enter the US market for{" "}
        <br />
        food, cosmetics and dietary supplements.
      </h2>
      <a href="#contact" className="G-btn">
        GET IN TOUCH
      </a>
    </div>
  );
}
