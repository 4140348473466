import React from "react";

import "./style.scss";

function SuccessMessage(props) {
  return (
      <div className="P-success-message G-flex G-flex-column G-align-center G-justify-center">
        <h2 className="G-mb-1">Thank you for your message!</h2>
        <h3>We will contact you as soon as possible.</h3>
      </div>
  );
}

export default SuccessMessage;
