import "./style.scss";
import Service1 from "../../../assets/images/service1.jpg";
import Service2 from "../../../assets/images/service2.jpg";
import Service3 from "../../../assets/images/service3.jpeg";
import { Link } from "react-router-dom";

export default function Services() {
  const services = [
    {
      name: "FOOD",
      img: Service1,
      subCategories: [
        {
          name: "Food facility registration and US agent services",
          link: "facility",
        },
        {
          name: "Fodd label review/development",
          link: "label",
        },
        {
          name: "Acidified foods (AF) or low-acid canned foods (LACF)",
          link: "acid",
        },
        {
          name: "FSVP requirements",
          link: "fsvp",
        },
      ],
    },
    {
      name: "COSMETICS",
      img: Service3,
      subCategories: [
        {
          name: "VCRP",
          link: "vcrp",
        },
        {
          name: "Cosmetics label review/development",
          link: "cosmetic",
        },
      ],
    },
    {
      name: "DIETARY SUPPLEMENTS",
      img: Service2,
      subCategories: [
        {
          name: "Food facility registration and US agent services",
          link: "facility",
        },
        {
          name: "Dietart supplements label review/development",
          link: "dietary",
        },
      ],
    },
  ];
  return (
    <div
      id="services"
      className="P-home-services G-mt-6 G-flex G-align-center G-justify-center G-flex-column"
    >
      <h2>SERVICES</h2>
      <div className="P-services-list G-flex G-align-center G-justify-between G-mt-6">
        {services.map((item, index) => (
          <div
            className="P-service-item G-flex G-align-center G-justify-center G-flex-column"
            style={{ backgroundImage: `url(${item.img})` }}
            key={index}
          >
            {item.subCategories.map((item, index) => (
              <Link to={item.link} className="P-sub-item" key={index}>
                {item.name}
              </Link>
            ))}

            <div className="P-layer" />
          </div>
        ))}
      </div>
    </div>
  );
}
