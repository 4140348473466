import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./style.scss";
export default function Header() {
  return (
    <header className="G-flex G-align-cetner G-justify-between">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <div className="P-categories G-flex G-align-center">
        <div className="dropdown">
          <span className="dropbtn">Food</span>
          <div className="dropdown-content">
            <Link to="/facility">
              Food Facility Registration And US Agent services
            </Link>
            <Link to="/label">Food Label Review/Development</Link>
            <Link to="/acid">
              Acidified Foods (AF) or Low-Acid Canned Foods (LACF)
            </Link>
            <Link to="/fsvp">FSVP requirements </Link>
          </div>
        </div>
        <div className="dropdown">
          <span className="dropbtn">Dietary Suppliement</span>
          <div className="dropdown-content">
            <Link to="/facility">
              Food Facility Registration And US Agent services
            </Link>
            <Link to="/dietary">
              Dietary Supplements Label Review/Development
            </Link>
          </div>
        </div>
        <div className="dropdown">
          <span className="dropbtn">Cosmetics</span>
          <div className="dropdown-content">
            <Link to="/vcrp">VCRP</Link>
            <Link to="/cosmetic">Cosmetics Label Review/Development</Link>
          </div>
        </div>
      </div>
    </header>
  );
}
