import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_white.png";
import insta from "../../assets/images/instagram.png";
import fb from "../../assets/images/facebook.png";

import "./style.scss";
export default function Footer() {
  return (
    <footer className="G-flex G-align-cetner G-justify-between">
      <img src={logo} alt="logo" />
      <div className="P-links G-flex G-align-center">
        <a href="#about">About us</a>
        <a href="#services" className="G-ml-2">Services</a>
        <a href="#contact" className="G-ml-2">Get in touch</a>
      </div>
      <div className="P-icons G-flex G-align-center">
        <a href="">
          <img src={fb} alt="" className="G-mr-2" />
        </a>
        <a href="">
          <img src={insta} alt="" />
        </a>
      </div>
    </footer>
  );
}
