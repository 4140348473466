import { useState } from "react";
import Validator from "../../platform/services/validator";
import UserController from "../../platform/api/user";
import SuccessMessage from "../Success-message";
import Modal from "../Modal";

import "./style.scss";

export default function Contact() {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const change = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const submit = async () => {
    if (
      data.name.length > 0 &&
      data.message.length > 0 &&
      Validator.isEmailValid(data.email)
    ) {
      const result = await UserController.sendInfo(data);
      if (result.success) {
        setIsOpen(true);
        setData({
          name: "",
          email: "",
          message: "",
        });
      }
    }
  };

  return (
    <div
      id="contact"
      className="P-contact G-mt-6 G-flex G-align-center G-justify-center G-flex-column"
    >
      <h2>GET IN TOUCH</h2>
      <div className="P-contact-banner G-flex G-justify-center G-flex-column G-mt-6">
        <input
          type="text"
          value={data.name}
          name="name"
          onChange={change}
          placeholder="Name"
        />
        <input
          type="text"
          value={data.email}
          name="email"
          placeholder="Email"
          onChange={change}
        />
        <textarea
          type="text"
          name="message"
          value={data.message}
          placeholder="Message"
          onChange={change}
        />
        <button onClick={submit} className="G-btn">
          GET IN TOUCH
        </button>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SuccessMessage />
      </Modal>
    </div>
  );
}
